<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center justify-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-2" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(tip,tipIndex) in banner.tip" :key="tipIndex" class="mb-3">{{tip}}</div>
                  </div>
                </v-card-title>
                <v-card-actions class="pa-0 mt-10 justify-center" v-if="banner.btn">
                  <v-btn
                      rounded
                      color="#0568FD"
                      width="160"
                      height="50"
                      class="font-size-20"
                  >
                    <span class="color-FFF">{{ banner.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>

    <!-- <v-card class="warps warp1" :flat="true">
      <div class="con">
      </div>
    </v-card> -->


    <!-- 动画也太酷了吧 -->
    <!-- <div  style="width:1200px;margin:0 auto;">  -->
      <div class="d-flex justify-center width-per-100 pb-15" style="width:1200px;margin:0 auto;">
        <v-card class="max-width-1200 width-per-100" flat color="transparent">
          <div class="public-title">
            <p>PRODUCT MODULE</p>
            <div>
              <span>产品模块</span>
            </div>
          </div>
          <div class="d-flex justify-center pt-5" style="width:900px;margin:0 auto;">
            <transition name="slide-fade" appear>
              <v-img style="background-color: transparent"
                    width="400" height="260" min-height="260"
                    src="https://h5.ophyer.cn/official_website/other/assistantAccount-home-img1.png">
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        size="80"
                        width="8"
                        color="grey lighten-3"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </transition>
            <transition name="slide-fade-reverse" appear>
              <div style="margin-left: 80px;width: 385px;"
                  class="d-flex align-center">
                <div>
                  <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">CRM系统</v-card-title>
                  <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                    <div class="width-per-100 white-space-pre-wrap">依据粉丝在公众号内的行为或属性自动打标签，帮助公众号实现精细化运营。独家数据库，支持多维度查看粉丝数据</div>
                  </v-card-text>
                </div>
              </div>
            </transition>
          </div>
        </v-card>
      </div>
    <!-- </div> -->


    <!-- 搭建属于你的场景 -->
    <div class="d-flex justify-center width-per-100 pb-15" style="width:1200px;margin:0 auto;">
      <v-card class="max-width-1200 width-per-100" flat>
        <div class="d-flex justify-center" style="width:850px;margin:0 auto;">
          <transition name="slide-fade" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">营销应用</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">提供标准化的互动应用解决公众号的互动营销需求。公众号可在平台内完成抽奖、口令兑换等活跃用户的活动。</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <v-img style="background-color: transparent"
                   max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/assistantAccount-home-img2.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
      </v-card>
    </div>

    <!-- 产品插件 -->
    <v-card class="warps warp3" :flat="true">
      <div class="public-title">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <span>产品优势</span>
          <span>深耕行业数十年，提供安全、稳定的技术服务</span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="3" v-for="n in warp3" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- 合作客户 -->
    <v-card class="warps warp4" :flat="true">
      <div class="public-title">
        <p>PARTNER</p>
        <div>
          <span>合作客户</span>
        </div>
      </div>
      <div class="con">
        <img src="https://h5.ophyer.cn/official_website/other/assistantAccount-home-warp4-img.png" alt="" />
      </div>
    </v-card>

   
  </v-app>
</template>

<script>
import textData from './textData.js';

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      data: textData.Home,
      tab: null,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/assistantAccount-home-banner01.png",
          title: ["公众号助手"],
          titleColor:"#FFFFFF",
          tip: ["基于公众号管理粉丝关系，了解粉丝行为及爱好，通过数据让营销更聪明"],
          // btn:'立即咨询'
        },
      ],
      warp3: [
        { 
          id:1, 
          src: require('@/assets/images/icon/assistantAccount-home-warp3-img1.png'), 
          tit:'勾勒用户画像',
          content:'积累客户，打通数据，将成为您企业未来宝贵的数据资产'
        },
        { 
          id:2,  
          src: require('@/assets/images/icon/assistantAccount-home-warp3-img2.png'), 
          tit:'提升营销效率' ,
          content:'千人千面的的个性推送，促进营销转化'
        },
        { 
          id:3,  
          src: require('@/assets/images/icon/assistantAccount-home-warp3-img3.png'), 
          tit:'降低运营成本',
          content:'自动化营销，减少企业人工运营成本' 
        },
        { 
          id:4,  
          src: require('@/assets/images/icon/assistantAccount-home-warp3-img4.png'), 
          tit:'精准触达用户',
          content:'依托大数据，精准筛选用户，随时触达，提升转化和留存' 
        },

      ],

    }
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>


.warps {
  margin: 0 auto;
  text-align: center;
  width: 1200px !important;
}

.warp1 {
  padding-top: 60px;
  .con {
    margin-top: 20px;
    width: 1200px;
    height: 500px;
    background: #a8a8a8;
  }
}


.warp3 {
  margin-top: -25px;
  margin-bottom: -10px;
  .public-title {
    margin-bottom: -20px;
  }
  .img_title {
    width: 266px;
    margin: 15px auto;
    text-align: center;
    img {
      margin: 0 auto;
      width: 120px;
      height: 120px;
    }
    h2 {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      margin-top: 10px;
      line-height: 20px;
    }
  }
}

.warp4 {
  margin-bottom: 60px;
  .con {
    height: 214px;
  }
}

</style>
